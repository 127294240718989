import React from 'react';
import classNames from 'classnames';
import { withTheme, ThemeProps } from 'styled-components';

import { Link as LinkScroll } from 'react-scroll';
import { Link as LinkGatsby } from 'gatsby';
import { smoothLinkProps } from '@pagerland/common/src/utils';

import Container from '@pagerland/common/src/components/Container';
import Box from '@pagerland/common/src/components/Box';
import List from '@pagerland/common/src/components/List';
import Button from '@pagerland/common/src/components/Button';
import NavbarWrapper from '@pagerland/common/src/components/Navbar';
import useIsWindowScrollMoreThan from '@pagerland/common/src/hooks/useIsWindowScrollMoreThan';

import Logo from '@pagerland/themes/src/Corporate/components/Logo';

import data from '@pagerland/themes/src/Corporate/data';
import { LogoWrapper, MobileWrapper, NavbarLink, ToggleButton, Wrapper } from './styled.components';
import { zIndex } from '@pagerland/themes/src/Corporate/styles';
import {useNavbar} from '../../staticQueries/useMdxInfo';

type PropsInside = {
  /**
   * Main wrapper props
   * @See @pagerland/common/src/components/Box
   */
  WrapperProps?: any;
  /**
  * Navbar container props
  * @See @pagerland/common/src/components/Container
  */
  ContainerProps?: any;
  /**
  * Props of menu container
  * @See @pagerland/common/src/components/Box
  */
  MenuItemsListProps?: any;
  /**
  * Main wrapper props
  * @See @pagerland/common/src/components/Box
  */
  LinksWrapperProps?: any;
  /**
  * Single link props
  */
  LinkProps?: any;
  /**
  * Single link props
  */
  LinkPropsOtherPages?: any;
  /**
  * Logo element props
  */
  LogoProps?: any;
  /**
   * Logo element props
   */
  LogoPropsOtherPages?: any;
  /**
  * Additional buttons wrapper props
  */
  ActionsWrapperProps?: any;
  /**
  * Additional button props
  */
  ActionProps?: any;
  /**
  * Additional button props
  */
  ActionPropsOtherPages?: any;
  /**
  * Toggle RWD menu props
  */
  ToggleButtonProps?: any;
  /**
  * Props of mobile menu wrapper
  */
  MobileMenuProps?: any;
  /**
  * Props of mobile menu toggle
  */
  MobileMenuToggleProps?: any;
  /**
   * location.pathname
   */
  location?: string;
};
type Props = PropsInside&ThemeProps<any>;

const Navbar: React.FC<Props> = ({
  WrapperProps,
  ContainerProps,
  MenuItemsListProps,
  LogoProps,
  LogoPropsOtherPages,
  LinksWrapperProps,
  LinkProps,
  LinkPropsOtherPages,
  ActionsWrapperProps,
  ActionProps,
  ActionPropsOtherPages,
  ToggleButtonProps,
  MobileMenuProps,
  MobileMenuToggleProps,
  theme,
  location,
}) => {
  const {actions, links} = useNavbar();
  const isNavbarSticky = useIsWindowScrollMoreThan(0);
  const isThemeDark = theme.mode === 'dark';

  const isHomePage = location && location === '/';
  const Props = isHomePage ? LinkProps : LinkPropsOtherPages;

  const menu = onClick => (
    <>
      {links && (
        <List {...LinksWrapperProps}>
          {links.map(({ label, to, href,/*...link*/ }, i) => {
            let link = isHomePage ? {to: to} : {to: href};
            return (
              <List.Item key={i}>
                <NavbarLink {
                  ...{
                    ...Props, 
                    color: (!isThemeDark && isNavbarSticky) 
                      ? 'black' : 
                      (!isThemeDark && !isNavbarSticky) 
                        ? 'black'
                        : 'white'
                    }
                  } 
                  onClick={onClick} {...link}
                >
                  {label}
                </NavbarLink>
              </List.Item>
            );
          })}
          </List>
        )}
      {actions && (
        <Box {...ActionsWrapperProps}>
          {actions.map(({ label, ...link }, i) => (
            <Button {...ActionProps} onClick={onClick} {...link} key={i}>
              {label}
            </Button>
          ))}
        </Box>
      )}
    </>
  );

  return (
    <NavbarWrapper
      rwdMenu={({ onToggle, isOpen, onClose }) => (
        <MobileWrapper isActive={isOpen} {...MobileMenuProps}>
          <ToggleButton isActive={isOpen} toggleButton={onToggle} {
                ...{
                  ...MobileMenuToggleProps, 
                  color: (!isThemeDark && isNavbarSticky) 
                      ? 'black' : 
                      (!isThemeDark && !isNavbarSticky) 
                        ? 'black'
                        : 'white'
                }
              }
          />
          {menu(onClose)}
        </MobileWrapper>
      )}
    >
      {({ onToggle, isOpen, onClose }) => (
        <Wrapper
          className={classNames({
            sticky: isNavbarSticky,
          })}
          {...WrapperProps}
        >
          <Container {...ContainerProps}>
            <Box flexBox alignItems="center">
              {isHomePage && (
                <LogoWrapper {...LogoProps}>
                  <Logo isNavbarSticky={isNavbarSticky} />
                </LogoWrapper>
              )}
              {!isHomePage && (
                <LogoWrapper {...LogoPropsOtherPages}>
                  <Logo isNavbarSticky={isNavbarSticky} />
                </LogoWrapper>
              )}
              <Box {...MenuItemsListProps}>{menu(onClose)}</Box>
              <ToggleButton isActive={isOpen} toggleButton={onToggle}  {
                ...{
                  ...ToggleButtonProps,
                  barColor: (!isThemeDark && isNavbarSticky) 
                      ? 'black' : 
                      (!isThemeDark && !isNavbarSticky) 
                        ? 'white'
                        : 'white',
                  color: (!isThemeDark && isNavbarSticky) 
                      ? 'black' : 
                      (!isThemeDark && !isNavbarSticky) 
                        ? 'black'
                        : 'white'
                }
              } />
            </Box>
          </Container>
        </Wrapper>
      )}
    </NavbarWrapper>
  );
};

Navbar.defaultProps = {
  WrapperProps: {
    stickyBgColor: 'background',
  },
  ContainerProps: {
    py: 2,//4,
  },
  MenuItemsListProps: {
    display: {
      _: 'none',
      lg: 'flex',
    },
    alignItems: 'center',
    width: '100%',
  },
  LogoProps: {
    as: LinkScroll,
    to: 'welcome',
    ...smoothLinkProps,
    mx: {
      _: 'auto',
      md: '0',
    },
  },
  LogoPropsOtherPages: {
    as: LinkGatsby,
    href: '/',
    ...smoothLinkProps,
    mx: {
      _: 'auto',
      md: '0',
    },
  },
  LinksWrapperProps: {
    display: 'flex',
    flexDirection: {
      _: 'column',
      lg: 'row',
    },
    alignItems: 'center',
    ml: 'auto',
    mr: {
      _: 'auto',
      lg: 0,
    },
  },
  LinkProps: {
    mx: {
      _: 0,
      lg: 3,
    },
    my: {
      _: 3,
      lg: 0,
    },
    as: LinkScroll,
    activeColor: 'brand',
    ...smoothLinkProps,
  },
  LinkPropsOtherPages: {
    mx: {
      _: 0,
      lg: 3,
    },
    my: {
      _: 3,
      lg: 0,
    },
    as: LinkGatsby,
    activeColor: 'brand',
    ...smoothLinkProps,
  },
  ActionsWrapperProps: {
    mt: {
      _: 2,
      lg: 0,
    },
    ml: {
      _: 0,
      lg: 3,
    },
  },
  ActionProps: {
    variant: 'brand',
    size: 'small',
    //as: 'a',
    as: LinkScroll,
  },
  ActionPropsOtherPages: {
    variant: 'brand',
    size: 'small',
    //as: 'a',
    as: LinkGatsby,
  },
  ToggleButtonProps: {
    buttonWidth: 24,
    position: 'absolute',
    right: 3,
    color: 'invert',
    display: {
      lg: 'none',
    },
  },
  MobileMenuProps: {
    display: {
      _: 'flex',
      lg: 'none',
    },
    position: 'fixed',
    backgroundColor: 'background',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: zIndex.menuNav,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  MobileMenuToggleProps: {
    buttonWidth: 24,
    color: 'brand',
    position: 'absolute',
    right: 3,
    top: 3,
  },
};

export default withTheme(Navbar);
