import axios from 'axios';
import * as Yup from 'yup';

const hubspotUrl = (portalId: string, formGuid: string) => `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`;

interface HubspotFormRequest {
  portalId: string;
  formGuid: string;
  submittedAt: string;
  fields: {
    objectTypeId: string;
    name: string;
    value: string|number;
  }[];
  context: {
    //hutk: string;
    pageUri: string;
    pageName: string;
  };
  /*legalConsentOptions: {
    consent: {
      consentToProcess: boolean;
      text: string;
      communications: {
        value: boolean;
        subscriptionTypeId: number;
        text: string;
        legalBasis?: string;
      }[];
    };
  };*/
}
interface Props {
    name: string;
    surname: string;
    email: string;
    company: string;
    position: string;
    message: string;
    language?: 'IT'|'EN';
    pageName?: string;
}

const createHubspotTicket = async (props: Props) => {
  const schema = Yup.object().shape({
    name: Yup.string().required(),
    surname: Yup.string().required(),
    email: Yup.string().email().required(),
    company: Yup.string().required(),
    position: Yup.string().required(),
    message: Yup.string().required(),
  });

  try {
    const values = await schema.validate(props);

    const portalId='9219618';
    const formGuidIT = 'fc1ae9d8-ffb4-4426-a07f-57f6854fa481';
    const formGuidEN = '5daed5ef-adea-48e7-bbc3-ebce3aef90d9';
    const formGuid = props.language && props.language == 'EN' ? formGuidEN : formGuidIT;
    const pageName = props.pageName ? props.pageName : 'Home Page';

    const url = hubspotUrl(portalId, formGuid);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      }
    }
    const data: HubspotFormRequest = {
      portalId,
      formGuid,
      submittedAt: `${Date.now()}`,
      fields: [
        {objectTypeId: "0-1", name: "hs_lead_status", value: "NEW"},
        {objectTypeId: "0-1", name: "lifecyclestage", value: "subscriber"},
        {objectTypeId: "0-2", name: "hs_lead_status", value: "NEW"},
        {objectTypeId: "0-2", name: "type", value: "PROSPECT"},
        {objectTypeId: "0-5", name: "source_type", value: "FORM"},
        {objectTypeId: "0-5", name: "hs_ticket_category", value: "GENERAL_INQUIRY"},
        {objectTypeId: "0-5", name: "hs_ticket_priority", value: "MEDIUM"},
        {objectTypeId: "0-1", name: "email", value: values.email},
        {objectTypeId: "0-1", name: "firstname", value: values.name},
        {objectTypeId: "0-1", name: "lastname", value: values.surname},
        {objectTypeId: "0-1", name: "jobtitle", value: values.position},
        {objectTypeId: "0-2", name: "name", value: values.company},
        {objectTypeId: "0-2", name: "budget", value: 5000},
        {objectTypeId: "0-1", name: "message", value: values.message},
        {objectTypeId: "0-5", name: "subject", value: "[Koent] Richiesta di contatto"},
        {objectTypeId: "0-5", name: "content", value: 'Nuova richiesta di contatto dal form "ContactUs" del sito Koent'},
      ],
      context: {
        //hutk: 'hutk',
        pageUri: 'https://koent.it',
        pageName: pageName,
      },
      /*legalConsentOptions:{
        consent: {
          consentToProcess: true,
          text:"I agree to allow Koent to store and process my personal data.",
          communications:[
            {
              value:true,
              subscriptionTypeId:999,
              text:"I agree to receive marketing communications from Example Company."
            },
            {
              value: true,
              subscriptionTypeId: 11694150,
              text:"Accetto di ricevere altre comunicazioni da Koent.",
              legalBasis: "LEGITIMANTE_INTEREST_PQL",
            },
          ],
        },
      },*/
    };

    const res = await axios.post(url, data, config);

    return {
        send: true,
        errors: null,
        response: res,
    }
  } catch (err: any) {
    return {
        send: false,
        errors: err,
        response: null,
    }
  }
}

export default createHubspotTicket;
