import * as Yup from 'yup';
import textToMultiline from '@pagerland/common/src/utils/textToMultiline';

//import background2x from './assets/header-photo@2x.jpg';
//import background from './assets/header-photo.jpg';
import background2x from './assets/white-stylus-and-a-tablet-lay-on-a-dark-table.jpg';
import background from './assets/white-stylus-and-a-tablet-lay-on-a-dark-table.jpg';
import Goals from './assets/our-goals.png';
import Goals2x from './assets/our-goals@2x.png';
import Mission from './assets/our-mission.png';
import Mission2x from './assets/our-mission@2x.png';
import Team from './assets/team.png';
import Team2x from './assets/team@2x.png';
import Icon from './assets/icon.png';

import Consulting from './assets/consulting.svg';
import Auditing from './assets/auditing.svg';
import Strategy from './assets/strategy.svg';
import Avatar1 from './assets/avatars/avatar-1.jpg';
import Avatar12x from './assets/avatars/avatar-1@2x.jpg';
import Avatar2 from './assets/avatars/avatar-2.jpg';
import Avatar22x from './assets/avatars/avatar-2@2x.jpg';
import Avatar3 from './assets/avatars/avatar-3.jpg';
import Avatar32x from './assets/avatars/avatar-3@2x.jpg';
//import Avatar4 from './assets/avatars/avatar-4.jpg';
//import Avatar42x from './assets/avatars/avatar-4@2x.jpg';
//import Avatar5 from './assets/avatars/avatar-5.jpg';
//import Avatar52x from './assets/avatars/avatar-5@2x.jpg';
//import Avatar6 from './assets/avatars/avatar-6.jpg';
//import Avatar62x from './assets/avatars/avatar-6@2x.jpg';
//import Avatar7 from './assets/avatars/avatar-7.jpg';
//import Avatar72x from './assets/avatars/avatar-7@2x.jpg';
//import Avatar8 from './assets/avatars/avatar-8.jpg';
//import Avatar82x from './assets/avatars/avatar-8@2x.jpg';

export default {
  title: 'Koent',
  navbar: {
    links: [
      {
        to: 'whatWeDo',
        href: '/#whatWeDo',
        label: 'Servizi',
      },
      /*{
        to: 'offerB2B',
        label: 'Portale',
      },
      {
        to: 'goals',
        label: 'Obiettivi',
      },*/
      {
        to: 'mission',
        href: '/#mission',
        label: 'Mission',
      },
      {
        to: 'projects',
        href: '/#projects',
        label: 'Progetti',
      },
      /*{
        to: 'testimonials',
        label: 'Testimonials',
      },*/
      /*{
        to: 'pricing',
        label: 'Pricing',
      },*/
      {
        to: 'contact',
        href: '/#contact',
        label: 'Contatti',
      },
    ],
    actions: [
      /*{
        href:
          '#contact',
        label: 'Buy theme',
        target: '_blank',
      },*/
      {
        to: 'newsletter',
        label: 'Resta aggiornato',
      },
    ],
  },
  welcome: {
    background: {
      src: background,
      srcSet: `${background} 1x, ${background2x} 2x`,
      alt: 'Spa e centro termale',
    },
    title: 'Koent',
    subtitle: 'Benvenuto dove la qualità incontra la tecnologia',
    description:
      'Siamo una boutique tecnologica, partner unico per tutte le esigenze digitali dei brand che puntano al successo, anticipando l\'eccellenza.',
    actions: [
      /*{
        as: 'a',
        href:
          'https://themeforest.net/item/pagerland-react-and-gatsby-landing-page-templates/26548567',
        children: 'Buy our theme',
        target: '_blank',
      },*/
      {
        to: 'whatWeDo',
        children: 'Cosa facciamo',
      },
      {
        to: 'contactUs',
        children: 'Contattaci',
        variant: 'outline',
        color: 'white',
      },
    ],
  },
  whatWeDo: {
    subtitle: 'Che cosa facciamo?',
    title: 'I nostri Servizi',
    services: [
      {
        icon: Consulting,
        title: 'Mobile App',
        text:
          'Business moderni richiedono strumenti moderni. Una buona app permette di offrire servizi avanzati, aumentare la fidelizzazione, supportare i tuoi dipendenti.',
      },
      {
        icon: Auditing,
        title: 'Sviluppo Web',
        text:
          'Oggi giorno un classico sito web non è più sufficiente. Lascia che un sito progettato ad hoc, responsive, studiato per tutti i dispositivi, converta i tupi visitatori in clienti.',
      },
      {
        icon: Auditing,
        title: 'Web & Cloud',
        text:
          'Sviluppiamo applicazioni web in cloud ed ecommerce con nuove tecnologie standard di settore per offrire ai tuoi clienti la migliore esperienza di navigazione possibile.',
      },
      {
        icon: Consulting,
        title: 'E-commerce',
        text:
          "Che tu venda servizi o prodotti in B2B o B2C, un e-commerce può essere il tuo miglior punto vendita. Un design coerente, una velocità sorprendente ed uno stack tecnologico all'avanguardia sono la tua nuova chiave per il successo.",
      },
      {
        icon: Strategy,
        title: 'Gestionali',
        text:
          'Sfruttiamo la potenza del cloud e delle nostre soluzioni headless per dare forza alle tue necessità in ambito gestionale, creando software che si adattano ad ogni casistica.',
      },
      {
        icon: Strategy,
        title: 'Sviluppo Web 3.0',
        text:
          'Sviluppiamo applicazioni decentralizzate tramite l\'uso di blockchain e smart contracts, per garantire la massima sicurezza dei dati nel momento in cui questi svolgano un ruolo chiave e sia necessario poterne garantire l\integrità in modo costante.',
      },
    ],
  },
  offerB2B: {
    subtitle: 'Cosa offriamo ai centri benessere?',
    title: 'La nostra offerta',
    services: [
      {
        icon: Consulting,
        title: 'Il portale',
        text:
          'Il portale di SPAcialista vi renderà visibili sin da subito ad un target molto specifico, permettendovi di promuovervi e di ospitare eenti esclusivi nella vostra location.',
      },
      {
        icon: Auditing,
        title: 'Il Marketing',
        text:
          'Sviluppiamo una strategia per aumentare la visibilità delle eccellenze italiane in ambito benessere, così che non dobbiate preoccuparvi di nulla, se non accogliere un numero sempre maggiore di ospiti nella vostra struttura.',
      },
      {
        icon: Strategy,
        title: 'La Strategia',
        text:
          'Forniamo consulenza specifica per indivuduare ed implementare le migliori strategie a 360 gradi, tramite i nostri esperti di Marketing, PR e sviluppo web, per creare soluzioni all\'avanguardia che vi permetteranno di massimizzare i vostri investimenti',
      },
    ],
  },
  offer: {
    subtitle: 'Cosa offriamo ai centri benessere?',
    title: 'La nostra offerta',
    services: [
      {
        icon: Consulting,
        title: 'Il portale',
        text:
          'Il portale di SPAcialista vi renderà visibili sin da subito ad un target molto specifico, permettendovi di promuovervi e di ospitare eenti esclusivi nella vostra location.',
      },
      {
        icon: Auditing,
        title: 'Il Marketing',
        text:
          'Sviluppiamo una strategia per aumentare la visibilità delle eccellenze italiane in ambito benessere, così che non dobbiate preoccuparvi di nulla, se non accogliere un numero sempre maggiore di ospiti nella vostra struttura.',
      },
      {
        icon: Strategy,
        title: 'La Strategia',
        text:
          'Forniamo consulenza specifica per indivuduare ed implementare le migliori strategie a 360 gradi, tramite i nostri esperti di Marketing, PR e sviluppo web, per creare soluzioni all\'avanguardia che vi permetteranno di massimizzare i vostri investimenti',
      },
    ],
  },
  goals: {
    img: {
      src: Goals,
      srcSet: `${Goals} 1x, ${Goals2x} 2x`,
    },
    subtitle: 'We stay focus on targets',
    title: 'Our goals',
    text:
      'Proin sed pharetra ligula. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nam commodo eu justo in posuere. Sed elementum, ipsum eu faucibus porta, odio mauris posuere quam, eu cursus tellus est et lacus. Aenean maximus velit ac malesuada ultricies. Vestibulum magna leo, convallis non elit ac, hendrerit sollicitudin tellus.',
    cta: {
      children: 'Read about our mission',
      href:
        'https://themeforest.net/item/pagerland-react-and-gatsby-landing-page-templates/26548567',
      target: '_blank',
      as: 'a',
    },
  },
  mission: {
    img: {
      src: Mission,
      srcSet: `${Mission} 1x, ${Mission2x} 2x`,
    },
    subtitle: 'Wha we’re dedicated to',
    title: 'Our mission',
    text:
      'Proin sed pharetra ligula. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nam commodo eu justo in posuere. Sed elementum, ipsum eu faucibus porta, odio mauris posuere quam, eu cursus tellus est et lacus. Aenean maximus velit ac malesuada ultricies. Vestibulum magna leo, convallis non elit ac, hendrerit sollicitudin tellus.',
    cta: {
      children: 'See what we offer',
      href:
        'https://themeforest.net/item/pagerland-react-and-gatsby-landing-page-templates/26548567',
      target: '_blank',
      as: 'a',
    },
  },
  supportCta: {
    subtitle: 'World class support',
    title: 'We’re here to help!',
    text:
      'Simply go to our support website and open a ticket to get fast and professional help directly from them Envato Elite authors.',
    buttons: [
      {
        label: 'Open support ticket',
        href: 'https://coffeecream.freshdesk.com/',
      },
    ],
  },
  team: {
    img: {
      src: Team,
      srcSet: `${Team} 1x, ${Team2x} 2x`,
    },
    subtitle: 'Il nostro valore più grande: le persone',
    title: 'Conosci il nostro fantastico team',
    text:
      'Così come riteniamo importante il vostro benessere, per noi è fondamentale sapere che le persone che compongono la nostra squadra siano felici e rilassate: il metodo Koent mette le persone al centro della sua visione, è il nostro team a prendersi cura di voi, e noi di loro.',
    cta: {
      label: 'Unisciti a noi, stiamo assumendo!',
      href:
        //'https://themeforest.net/item/pagerland-react-and-gatsby-landing-page-templates/26548567',
        'https://www.linkedin.com/company/koent',
    },
    people: [
      {
        avatar: {
          src: Avatar1,
          srcSet: `${Avatar1} 1x, ${Avatar12x} 2x`,
        },
        name: 'Davide Quattrocchi',
        position: 'Co-Founder, CEO',
      },
      {
        avatar: {
          src: Avatar2,
          srcSet: `${Avatar2} 1x, ${Avatar22x} 2x`,
        },
        name: 'Tommaso Pellegrino',
        position: 'Co-Founder, CTO',
      },
      {
        avatar: {
          src: Avatar3,
          srcSet: `${Avatar3} 1x, ${Avatar32x} 2x`,
        },
        name: 'Giacomo Bizzarri',
        position: 'Co-Founder, CFO',
      },
      /*{
        avatar: {
          src: Avatar4,
          srcSet: `${Avatar4} 1x, ${Avatar42x} 2x`,
        },
        name: 'Judith Black',
        position: 'Co-Founder, CEO',
      },
      {
        avatar: {
          src: Avatar5,
          srcSet: `${Avatar5} 1x, ${Avatar52x} 2x`,
        },
        name: 'Robert Edwards',
        position: 'Co-Founder, CEO',
      },
      {
        avatar: {
          src: Avatar6,
          srcSet: `${Avatar6} 1x, ${Avatar62x} 2x`,
        },
        name: 'Dianne Robertson',
        position: 'Co-Founder, CEO',
      },
      {
        avatar: {
          src: Avatar7,
          srcSet: `${Avatar7} 1x, ${Avatar72x} 2x`,
        },
        name: 'Shane Black',
        position: 'Co-Founder, CEO',
      },
      {
        avatar: {
          src: Avatar8,
          srcSet: `${Avatar8} 1x, ${Avatar82x} 2x`,
        },
        name: 'Nathan Fox',
        position: 'Co-Founder, CEO',
      },*/
    ],
  },
  projects: {
    img: {
      src: Team,
      srcSet: `${Team} 1x, ${Team2x} 2x`,
    },
    subtitle: 'Con chi lavoriamo',
    title: 'Scopri i nostri progetti, clienti e partner',
    text:
      'Una buona comunicazione è fondamentale per avere progetti di successo: il metodo Koent mette le persone al centro della sua visione, e queste sono le aziende con cui parliamo.',
    /*cta: {
      label: 'Unisciti a noi, stiamo assumendo!',
      href:
        //'https://themeforest.net/item/pagerland-react-and-gatsby-landing-page-templates/26548567',
        'https://www.linkedin.com/company/koent',
    },*/
    portfolio: [
      {
        avatar: {
          src: Icon,
          srcSet: `${Icon} 1x, ${Icon} 2x`,
          alt: 'Logo Koent',
        },
        /*background: {
          src: background,
          srcSet: `${background} 1x, ${background2x} 2x`,
          alt: 'Logo Koent',
        },*/
        name: 'SPAcialista',
        position: 'Milano',
      },
      {
        avatar: {
          src: Icon,
          srcSet: `${Icon} 1x, ${Icon} 2x`,
          alt: 'Logo Koent',
        },
        name: 'Zampa',
        position: 'Milano',
      },
      {
        avatar: {
          src: Icon,
          srcSet: `${Icon} 1x, ${Icon} 2x`,
          alt: 'Logo Koent',
        },
        name: 'MamaParking',
        position: 'Milano',
      },
      {
        avatar: {
          src: Icon,
          srcSet: `${Icon} 1x, ${Icon} 2x`,
          alt: 'Logo Koent',
        },
        name: 'Park My Wheelchair',
        position: 'Milano',
      },
      /*{
        avatar: {
          src: Icon,
          srcSet: `${Icon} 1x, ${Icon} 2x`,
          alt: 'Logo Koent',
        },
        name: 'AdvMediaLab',
        position: 'Urbania',
      },*/
      /*{
        avatar: {
          src: Icon,
          srcSet: `${Icon} 1x, ${Icon} 2x`,
          alt: 'Logo Koent',
        },
        name: 'Ospedale San Raffaele',
        position: 'Milano',
      },*/
      /*{
        avatar: {
          src: Icon,
          srcSet: `${Icon} 1x, ${Icon} 2x`,
          alt: 'Logo Koent',
        },
        name: 'SindacApp',
        position: 'Marche',
      },*/
      /*{
        avatar: {
          src: Icon,
          srcSet: `${Icon} 1x, ${Icon} 2x`,
          alt: 'Logo Koent',
        },
        name: 'TaxiDriver',
        position: 'Milano',
      },*/
      {
        avatar: {
          src: Icon,
          srcSet: `${Icon} 1x, ${Icon} 2x`,
          alt: 'Logo Koent',
        },
        name: 'Studio071',
        position: 'Senigallia',
      },
    ],
  },
  testimonials: {
    subtitle: 'Our customer reviews',
    title: 'Testimonials',
    comments: [
      {
        author: 'Pat Cooper, Big Company SEO',
        text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean a tellus turpis. Suspendisse posuere quam consequat magna bibendum pretium. Vivamus in porta mauris. Vestibulum ut euismod purus. Sed eu malesuada elit. Curabitur posuere consequat gravida. Donec laoreet volutpat sapien, sed malesuada nisi pellentesque et. Etiam imperdiet nunc enim, sit amet tempor sapien molestie id.`,
      },
      {
        author: 'Orsola Jeroch, Bigger Company CTO',
        text: `Praesent nec commodo arcu. Vivamus convallis pretium malesuada. Aenean feugiat maximus suscipit. Fusce maximus aliquam ipsum, at hendrerit augue aliquet ac. Maecenas scelerisque, massa a fringilla imperdiet, velit purus gravida dolor, et blandit lorem nulla non eros.`,
      },
    ],
  },
  technologies: {
    subtitle: 'Cosa usiamo',
    title: 'Tecnologie',
    comments: [
      {
        author: 'Pat Cooper, Big Company SEO',
        text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean a tellus turpis. Suspendisse posuere quam consequat magna bibendum pretium. Vivamus in porta mauris. Vestibulum ut euismod purus. Sed eu malesuada elit. Curabitur posuere consequat gravida. Donec laoreet volutpat sapien, sed malesuada nisi pellentesque et. Etiam imperdiet nunc enim, sit amet tempor sapien molestie id.`,
      },
      {
        author: 'Orsola Jeroch, Bigger Company CTO',
        text: `Praesent nec commodo arcu. Vivamus convallis pretium malesuada. Aenean feugiat maximus suscipit. Fusce maximus aliquam ipsum, at hendrerit augue aliquet ac. Maecenas scelerisque, massa a fringilla imperdiet, velit purus gravida dolor, et blandit lorem nulla non eros.`,
      },
    ],
  },
  contactCta: {
    subtitle: 'Interested in our services?',
    title: 'Get a free quote!',
    text:
      'Ut at cursus justo, non luctus lacus. Proin cursus felis at dolor auctor molestie. Aenean fringilla dolor quis felis laoreet.',
    buttons: [
      {
        label: 'Get a quote',
        href:
          'https://themeforest.net/item/pagerland-react-and-gatsby-landing-page-templates/26548567',
      },
      {
        label: 'Contact us',
        href: 'https://coffeecream.freshdesk.com/',
        variant: 'outline',
      },
    ],
  },
  pricing: {
    title: 'Prezzi',
    prices: {
      currency: 'EUR',
      types: {
        a: 'Monthly',
        b: 'Annually',
      },
      sections: [
        {
          title: 'Bronze',
          price: {
            a: 'Richiedi',
            b: 'Richiedi',
          },
          features: [
            {
              text: 'Appari sul portale',
              check: true,
            },
            {
              text: 'Ricevi recensioni',
              check: true,
            },
            {
              text: 'Ricevi prenotazioni',
              check: false,
            },
            {
              text: 'Portale promozioni',
              check: false,
            },
            {
              text: 'Consulenza Marketing',
              check: false,
            },
            {
              text: 'Eventi esclusivi',
              check: false,
            },
          ],
          button: {
            text: 'Richiedi',
            ButtonProps: {
              as: 'a',
              href: 'https://mypags.app',
            },
          },
        },
        {
          title: 'Gold',
          price: {
            a: 'Richiedi',
            b: 'Richiedi',
          },
          features: [
            {
              text: 'Appari sul portale',
              check: true,
            },
            {
              text: 'Ricevi recensioni',
              check: true,
            },
            {
              text: 'Ricevi prenotazioni',
              check: true,
            },
            {
              text: 'Portale promozioni',
              check: true,
            },
            {
              text: 'Consulenza Marketing',
              check: false,
            },
            {
              text: 'Eventi esclusivi',
              check: false,
            },
          ],
          button: {
            text: 'Richiedi',
            ButtonProps: {
              as: 'a',
              href: 'https://mypags.app',
            },
          },
        },
        {
          title: 'Platinum',
          price: {
            a: 'Richiedi',
            b: 'Richiedi',
          },
          features: [
            {
              text: 'Appari sul portale',
              check: true,
            },
            {
              text: 'Ricevi recensioni',
              check: true,
            },
            {
              text: 'Ricevi prenotazioni',
              check: true,
            },
            {
              text: 'Portale promozioni',
              check: true,
            },
            {
              text: 'Consulenza Marketing',
              check: true,
            },
            {
              text: 'Eventi esclusivi',
              check: true,
            },
          ],
          button: {
            text: 'Richiedi',
            ButtonProps: {
              as: 'a',
              href: 'https://mypags.app',
            },
          },
        },
      ],
    },
  },
  contact: {
    subtitle: 'Scrivici o prendiamoci un caffè',
    title: 'Dove siamo',
    addresses: [
      {
        title: 'HQ - Milano',
        address: textToMultiline`Piazza IV Novembre 4,\n20124 Milano (MI)\nItalia`,
        email: 'info@koent.it',
        phone: '',
      },
      {
        title: 'Sede estiva - Senigallia',
        address: textToMultiline`Via A. Modigliani 23,\n60019 Senigallia (AN)\nItalia`,
        email: 'info@koent.it',
        phone: '',
      },
    ],
  },
  contactUs: {
    title: 'Contattaci',
    text:
      'Sei interessato a saperne di più sul nostro mondo, vuoi ricevere una quotazione o vuoi fare semplicemente quattro chiacchiere? Fantastico, lasciaci un messaggio e saremo ben lieti di risponderti nel minore tempo possibile!',
    sendButtonText: 'Invia messaggio',
    successMessage: 'Grazie per il tuo messaggio. Un referente del team Koent ti contatterà nel minor tempo possibile. A presto!',
    errorMessage: 'Pare che ci sia un errore. Per favore, riprova più tardi.',
    form: {
      validationSchema: Yup.object({
        name: Yup.string().max(20, 'Devono esserci meno di 15 caratteri').required('Richiesto'),
        surname: Yup.string().max(20, 'Devono esserci meno di 15 caratteri').required('Richiesto'),
        company: Yup.string().min(3, 'Deve contenere almeno 3 caratteri').required('Richiesto'),
        email: Yup.string().email('Deve essere una email valida').required('Richiesto'),
        position: Yup.string().required('Richiesto'),
        message: Yup.string().min(20, 'Must be at least 20 characters').required('Richiesto'),
      }),
      // eslint-disable-next-line no-undef
      onSubmit: values => window.alert(`Form sent with values ${JSON.stringify(values)}`),
      fields: [
        {
          name: 'name',
          label: 'Il tuo nome',
          placeholder: 'Il tuo nome',
          initialValue: '',
          fullSize: false,
        },
        {
          name: 'surname',
          label: 'Il tuo cognome',
          placeholder: 'Il tuo Cognome',
          initialValue: '',
          fullSize: false,
        },
        {
          name: 'company',
          placeholder: 'Inserisci il nome della azienda',
          label: 'Azienda',
          initialValue: '',
          fullSize: true,
        },
        {
          name: 'email',
          placeholder: 'La tua e-mail',
          label: 'E-mail',
          type: 'email',
          initialValue: '',
          fullSize: false,
        },
        {
          name: 'position',
          placeholder: 'Che posizione ricopri in azienda?',
          label: 'Posizione lavorativa',
          initialValue: '',
          fullSize: false,
        },
        {
          name: 'message',
          placeholder: 'Inserisci un messaggio o una breve descrizione del progetto che volete realizzare',
          label: 'Message',
          multiline: true,
          initialValue: '',
          fullSize: true,
        },
      ],
    },
  },
  newsletter: {
    subtitle: 'Resta aggiornato',
    title: 'Iscriviti alla nostra newsletter',
    buttonText: 'Iscriviti',
    inputPlaceholder: 'Il tuo indirizzo email',
  },
  copyright: textToMultiline`© 2021-2022 Koent S.r.l.\nPiazza IV Novembre 4, 20124 Milano (MI)\nP.Iva e C.F.: 12020000969\nCap. soc. €10.000 n.i.v.`,
};
