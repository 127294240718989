import { useStaticQuery, graphql } from "gatsby";
import { Mdx } from "../types/graphql-types";

type Query = {
  mdx: Mdx;
}

export const useHeaderFooter = () => {
  /*const { mdx } = useStaticQuery<Query>(
    graphql`
      query NAVBAR_STATIC {
        mdx {
          ...HeaderFooter
        }
      }
    `
  );*/

  const mdx = useComponentsData();
  return mdx.frontmatter.headerFooter;
}
export const useNavbar = () => {
  /*const { mdx } = useStaticQuery<Query>(
    graphql`
      query NAVBAR_STATIC {
        mdx {
          ...Navbar
        }
      }
    `
  );*/

  const mdx = useComponentsData();
  return mdx.frontmatter.navbar;
}
export const useWelcome = () => {
  /*const { mdx } = useStaticQuery<Query>(
    graphql`
      query WELCOME_STATIC {
        mdx {
          ...Welcome
        }
      }
    `
  );*/
  const mdx = useComponentsData();
  return mdx.frontmatter.welcome;
}
export const useWhatWeDo = () => {
  /* const { mdx } = useStaticQuery<Query>(
    graphql`
      query WHATWEDO_STATIC {
        mdx {
          ...WhatWeDo
        }
      }
    `
  ); */
  const mdx = useComponentsData();
  return mdx.frontmatter.whatWeDo;
}
export const useTeam = () => {
  /* const { mdx } = useStaticQuery<Query>(
    graphql`
      query TEAM_STATIC {
        mdx {
          ...Team
        }
      }
    `
  ); */
  const mdx = useComponentsData();
  return mdx.frontmatter.team;
}
export const useProjects = () => {
  /* const { mdx } = useStaticQuery<Query>(
    graphql`
      query PROJECTS_STATIC {
        mdx {
          ...Projects
        }
      }
    `
  ); */
  const mdx = useComponentsData();
  return mdx.frontmatter.projects;
}
export const useTechnologies = () => {
  /* const { mdx } = useStaticQuery<Query>(
    graphql`
      query TECHNOLOGIES_STATIC {
        mdx {
          ...Technologies
        }
      }
    `
  ); */
  const mdx = useComponentsData();
  return mdx.frontmatter.technologies;
}
export const useContact = () => {
  /* const { mdx } = useStaticQuery<Query>(
    graphql`
      query CONTACT_STATIC {
        mdx {
          ...Contact
        }
      }
    `
  ); */
  const mdx = useComponentsData();
  return mdx.frontmatter.contact;
}
export const useContactUs = () => {
  /* const { mdx } = useStaticQuery<Query>(
    graphql`
      query CONTACTUS_STATIC {
        mdx {
          ...ContactUs
        }
      }
    `
  ); */
  const mdx = useComponentsData();
  return mdx.frontmatter.contactUs;
}
export const useNewsletter = () => {
  /* const { mdx } = useStaticQuery<Query>(
    graphql`
      query NEWSLETTER_STATIC {
        mdx {
          ...Newsletter
        }
      }
    `
  ); */
  const mdx = useComponentsData();
  return mdx.frontmatter.newsletter;
}
export const useComponentsData = () => {
  const { mdx } = useStaticQuery<Query>(
    graphql`
      query COMPONENTSDATA_STATIC {
        mdx(fields: {source: {eq: "generalInformations"}}, slug: {eq: "componentsData"}) {
          ...ComponentsDataFragment
        }
      }
    `
  );
  return mdx;
}

export const headerFooterFragment = graphql`
  fragment ComponentsDataHeaderFooter on MdxFrontmatter {
    headerFooter {
      title
      copyright
    }
  }
`;
export const navbarFragment = graphql`
  fragment ComponentsDataNavbar on MdxFrontmatter {
    navbar {
      actions {
        to
        label
      }
      links {
        to
        href
        label
      }
    }
  }
`;
export const welcomeFragment = graphql`
fragment ComponentsDataWelcome on MdxFrontmatter {
  welcome {
    actions {
      to
      children
      variant
      color
    }
    title
    subtitle
    description
    background {
      alt
      src2x {
        extension
        publicURL
        childImageSharp {
          gatsbyImageData(
            #background: "transparent",
            #webpQuality: 70, 
            layout: FULL_WIDTH,
            )
        }
      }
      srcSet
      src {
        extension
        publicURL
        childImageSharp {
          gatsbyImageData(
            #background: "transparent", 
            #webpQuality: 70, 
            layout: FULL_WIDTH,
          )
        }
      }
    }
  }
}
`;
export const whatWeDoFragment = graphql`
  fragment ComponentsDataWhatWeDo on MdxFrontmatter {
    whatWeDo {
      subtitle
      title
      services {
        icon {
          extension
          publicURL
        }
        title
        text
      }
    }
  }
`;
export const teamFragment = graphql`
  fragment ComponentsDataTeam on MdxFrontmatter {
    team {
      img {
        srcSet
        src {
          extension
          publicURL
          childImageSharp {
            gatsbyImageData(
              #background: "transparent", 
              #webpQuality: 70, 
              layout: FULL_WIDTH,
            )
          }
        }
        src2x {
          extension
          publicURL
          childImageSharp {
            gatsbyImageData(
              #background: "transparent", 
              #webpQuality: 70, 
              layout: FULL_WIDTH,
            )
          }
        }
      }
      subtitle
      title
      text
      cta {
        label
        href
      }
      people {
        name
        position
        avatar {
          srcSet
          src {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(
                #background: "transparent", 
                #webpQuality: 70, 
                layout: FULL_WIDTH,
              )
            }
          }
          src2x {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(
                #background: "transparent", 
                #webpQuality: 70, 
                layout: FULL_WIDTH,
              )
            }
          }
        }
      }
    }
  }
`;
export const projectsFragment = graphql`
  fragment ComponentsDataProjects on MdxFrontmatter {
    projects {
      subtitle
      title
      text
      img {
        alt
        srcSet
        src {
          extension
          publicURL
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "transparent",
              formats: [AUTO, WEBP],
              webpOptions: {
                quality: 80
              },
              placeholder: NONE,
              layout: FULL_WIDTH
            )
          }
        }
        src2x {
          extension
          publicURL
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "transparent",
              formats: [AUTO, WEBP],
              webpOptions: {
                quality: 80
              },
              placeholder: NONE,
              layout: FULL_WIDTH
            )
          }
        }
      }
      portfolio {
        name
        position
        role
        activities
        url
        avatar {
          alt
          srcSet
          src {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(
                #backgroundColor: "transparent",
                #placeholder: BLURRED,
                #backgroundColor: "transparent",
                #placeholder: DOMINANT_COLOR,
                placeholder: NONE,
                formats: [AUTO, WEBP],
                webpOptions: {
                  quality: 80
                },
                layout: FULL_WIDTH
              )
            }
          }
          src2x {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent",
                formats: [AUTO, WEBP],
                webpOptions: {
                  quality: 80
                },
                placeholder: NONE,
                layout: CONSTRAINED,
                width: 200,
                #height: 200
              )
            }
          }
        }
      }
    }
  }
`;
export const technologiesFragment = graphql`
  fragment ComponentsDataTechnologies on MdxFrontmatter {
    technologies {
      subtitle
      title
      comments {
				author
         text
      }
    }
  }
`;
export const contactFragment = graphql`
  fragment ComponentsDataContact on MdxFrontmatter {
    contact {
      subtitle
      title
      addresses {
        title
        address
        email
        phone
      }
    }
  }
`;
export const contactUsFragment = graphql`
  fragment ComponentsDataContactUs on MdxFrontmatter {
    contactUs {
      title
      text
      sendButtonText
      successMessage
      errorMessage
      form {
        fields {
          name
          label
          placeholder
          initialValue
          fullSize
          multiline
        }
      }
    }
  }
`;
export const newsletterFragment = graphql`
  fragment ComponentsDataNewsletter on MdxFrontmatter {
    newsletter {
      subtitle
      title
      buttonText
      inputPlaceholder
    }
  }
`;
export const mdxFragment = graphql`
  fragment ComponentsDataFragment on Mdx {
    slug
    frontmatter {
      ...ComponentsDataHeaderFooter
      ...ComponentsDataNavbar
      ...ComponentsDataWelcome
      ...ComponentsDataWhatWeDo
      ...ComponentsDataTeam
      ...ComponentsDataProjects
      ...ComponentsDataTechnologies
      ...ComponentsDataContact
      ...ComponentsDataContactUs
      ...ComponentsDataNewsletter
    }
  }
`;