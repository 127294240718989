import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Reveal from 'react-reveal/Reveal';
import Fade from 'react-reveal/Fade';

import { Formik, Form } from 'formik';

import Box from '@pagerland/common/src/components/Box';
import Container from '@pagerland/common/src/components/Container';
import Typography from '@pagerland/common/src/components/Typography';
import Button from '@pagerland/common/src/components/Button';
import Input from '@pagerland/common/src/components/Formik/Input';
import Card from '@pagerland/common/src/components/Card';

import Liquid from '@pagerland/themes/src/Corporate/components/Liquids/B';

import phoneImg from '@pagerland/themes/src/MobileApp/assets/contact-phone.png';
import phoneImg2x from '@pagerland/themes/src/MobileApp/assets/contact-phone@2x.png';

import { PhoneImg } from './styled.components';
import data from '@pagerland/themes/src/Corporate/data';

import createHubspotTicket from './createHubspotTicket';

const ContactUsContainerOLD = (props) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [serverResponse, setServerResponse] = useState(``);

  return (
    <ContactUs
      {...props}
      form={{
        ...ContactUs.defaultProps.form,
        hasSuccessStatus: isSuccess,
        hasErrorStatus: isError,
        onSubmit: async (values, { setSubmitting }) => {
          //e.preventDefault();
          setIsSuccess(false);
          setIsError(false);

          setSubmitting(true);

          try {
            const response = await window.fetch(`/api/createTicket`, {
              method: `POST`,
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify(values),
            }).then(res => res.json());
            setServerResponse(response);
            console.warn(response);
            if (response?.send) {
              setIsSuccess(true);
            } else {
              setIsError(true);
            }
          } catch (_) {
            setIsError(true);
          }
        },
      }}
    />
  );
};

const ContactUsContainer = (props) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [serverResponse, setServerResponse] = useState(``);

  return (
    <ContactUs
      {...props}
      form={{
        ...ContactUs.defaultProps.form,
        hasSuccessStatus: isSuccess,
        hasErrorStatus: isError,
        onSubmit: async (values, { setSubmitting }) => {
          //e.preventDefault();
          setIsSuccess(false);
          setIsError(false);

          setSubmitting(true);

          try {
            const response = await createHubspotTicket(JSON.stringify(values));
            setServerResponse(response);
            console.warn(response);
            if (response?.send) {
              setIsSuccess(true);
            } else {
              setIsError(true);
            }
          } catch (_) {
            setIsError(true);
          }
        },
      }}
    />
  );
};

const ContactUs = ({
  SuccessMessageProps,
  SuccessMessageTextProps,
  ErrorMessageProps,
  ErrorMessageTextProps,
  name,
  title,
  successMessage,
  errorMessage,
  text,
  sendButtonText,
  form,
  WrapperProps,
  ContainerProps,
  InnerProps,
  ImgWrapperProps,
  ImgProps,
  LiquidProps,
  CardProps,
  FormProps,
  TitleProps,
  TextProps,
  ButtonProps,
}) => (
  <Reveal ssrReveal ssrFadeout effect="liquid" duration={600}>
  <Box {...WrapperProps}>
    {/*<Liquid {...LiquidProps} />*/}
    <Container name={name} id={name} {...ContainerProps}>
      <Box {...InnerProps}>
        <Card {...CardProps}>
          <Box {...FormProps}>
            <Fade duration={600} collapse when={!!form.hasSuccessStatus}>
              <Box pb={3}>
                <Card {...SuccessMessageProps}>
                  <Typography {...SuccessMessageTextProps}>{successMessage}</Typography>
                </Card>
              </Box>
            </Fade>
            <Fade duration={600} collapse when={!!form.hasErrorStatus}>
              <Box pb={3}>
                <Card {...ErrorMessageProps}>
                  <Typography {...ErrorMessageTextProps}>{errorMessage}</Typography>
                </Card>
              </Box>
            </Fade>
            <Formik
              validationSchema={form.validationSchema}
              onSubmit={form.onSubmit}
              initialValues={form.fields.reduce(
                (acc, field) => ({
                  ...acc,
                  [field.name]: field.initialValue,
                }),
                {},
              )}
            >
              <Form>
                <Fade cascade bottom duration={600}>
                  <Typography {...TitleProps}>{title}</Typography>
                  <Typography {...TextProps}>{text}</Typography>
                  <div display={'flex'}>
                    {form.fields.map(field => (
                      <Input key={field.name} {...field} />
                    ))}
                    <Button type="submit" {...ButtonProps}>
                      {sendButtonText}
                    </Button>
                  </div>
                </Fade>
              </Form>
            </Formik>
          </Box>
        </Card>
      </Box>
    </Container>
  </Box>
  </Reveal>
);

ContactUs.propTypes = {
  /**
   * Name of container, can be used for anchors
   */
  name: PropTypes.string.isRequired,
  /**
   * Title text
   */
  title: PropTypes.node,
  /**
   * Main content text
   */
  text: PropTypes.node,
  /**
   * Send button content
   */
  sendButtonText: PropTypes.node,
  /**
   * Form details
   */
  form: PropTypes.shape({
    /**
     * Formik validation schema
     * @see https://jaredpalmer.com/formik/docs/guides/validation
     */
    validationSchema: PropTypes.object,
    /**
     * Form submit function
     */
    onSubmit: PropTypes.func,
    /**
     * List of fields
     */
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Name of field
         */
        name: PropTypes.string.isRequired,
        /**
         * Type of field
         */
        type: PropTypes.string,
        /**
         * Placeholder for field
         */
        placeholder: PropTypes.string,
        /**
         * Initial value used in formik
         */
        initialValue: PropTypes.string,
        /**
         * Initial value used in formik
         */
        fullSize: PropTypes.bool,
      }),
    ),
  }),
  /**
   * Main wrapper props
   * @See ../common/components/Box
   */
  WrapperProps: PropTypes.object,
  /**
   * Container props
   * @See ../common/components/Container
   */
  ContainerProps: PropTypes.object,
  /**
   * Inner box props
   * @See ../common/components/Box
   */
  InnerProps: PropTypes.object,
  /**
   * Props list for phone image wrapper
   * @See ../common/components/Box
   */
  ImgWrapperProps: PropTypes.object,
  /**
   * Phone image props
   * @See ../common/components/Img
   */
  ImgProps: PropTypes.object,
  /**
   * Object with props for Liquid component.
   */
  LiquidProps: PropTypes.object,
  /**
   * Object with props for Card component.
   */
   CardProps: PropTypes.object,
  /**
   * Form wrapper props
   * @See ../common/components/Box
   */
  FormProps: PropTypes.object,
  /**
   * Title text props
   * @See ../common/components/Typography
   */
  TitleProps: PropTypes.object,
  /**
   * Main content text props
   * @See ../common/components/Typography
   */
  TextProps: PropTypes.object,
  /**
   * Send button props
   * @See ../common/components/Button
   */
  ButtonProps: PropTypes.object,
  /**
   * Props of success message
   * @See ../common/src/components/Box
   */
   SuccessMessageProps: PropTypes.object,
   /**
    * Props of success message text
    * @See ../common//components/Box
    */
   SuccessMessageTextProps: PropTypes.object,
   /**
    * Props of error message
    * @See ../common/components/Box
    */
   ErrorMessageProps: PropTypes.object,
   /**
    * Props of error message text
    * @See ../common/components/Box
    */
   ErrorMessageTextProps: PropTypes.object,
};

ContactUs.defaultProps = {
  /*WrapperProps: {
    overflow: 'hidden',
    mt: -60,
    pt: 60,
  },*/
  WrapperProps: {
    overflow: 'hidden',
    py: {
      _: 60,
      lg: 150,
    },
  },
  InnerProps: {
    flexBox: true,
    /*pt: {
      _: 60,
      lg: 120,
    },
    pb: {
      _: 90,
      lg: 180,
    },
    ml: {
      _: 0,
      lg: 700,
    },*/
    position: 'relative',
  },
  FormProps: {
    flex: {
      _: '1',
      lg: '0 0 370px',
    },
  },
  TitleProps: {
    as: 'h2',
    variant: 'h2',
    mb: {
      _: 3,
      lg: 4,
    },
  },
  TextProps: {
    //color: 'gray.1',
    mb: {
      _: 3,
      lg: 4,
    },
  },
  ButtonProps: {
    variant: 'primary',
    mt: {
      _: 3,
      lg: 4,
    },
  },
  ImgWrapperProps: {
    position: 'absolute',
    top: -60,
    left: -860,
    zIndex: -1,
  },
  ImgProps: {
    src: phoneImg,
    srcSet: `${phoneImg} 1x, ${phoneImg2x} 2x`,
    alt: 'Contact us',
  },
  /*LiquidProps: {
    top: 140,
    zIndex: -2,
    left: -600,
    position: 'absolute',
  },*/
  LiquidProps: {
    position: 'absolute',
    maxWidth: {
      _: 'none',
      //lg: 770,
    },
    width: {
      _: '130%',
      lg: '100%',
    },
    zIndex: -1,
    transform: 'scale(1, -1)',
  },
  CardProps: {
    textAlign: 'center',
    p: {
      _: 4,
      lg: 5,
    },
    width: '100%',
  },
  SuccessMessageProps: {
    bg: 'green.6',
    color: 'gray.6',
  },
  SuccessMessageTextProps: {
    fontWeight: 600,
  },
  ErrorMessageProps: {
    bg: 'red.6',
    color: 'gray.6',
  },
  ErrorMessageTextProps: {
    fontWeight: 600,
  },
  ...data.contactUs,
};

export default ContactUsContainer;
